import React,{useEffect} from 'react'
import Icons from "../utils/icon";
import Logo from "../utils/images/Logo.png";
import toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <div className='font-out border-t border-gray-300'>
        <div className="flex justify-between p-20 md:flex-col md:gap-10 md:px-3 sm:py-10">
        <div className="w-1/2 md:w-full">
          <img src={Logo} alt="logo" className="w-28 sm:w-24" />
          <h1 className="text-3xl font-bold mt-2 lg2:text-2xl sm:text-[1.3rem]">
          Unlock Your Next Chapter: DevJobs – Your Gateway to Dream Opportunities!
          </h1>
          <p className="sm:text-[0.8rem]">2023, DevJobs</p>
          <div
            className="flex gap-3 cursor-pointer"
            style={{ fontSize: "22px", color: "#146eb4" }}
          >
             <a href="https://t.me/+jQsb9IOIklU3YTU1" target="_blank" className='underline-none text-inherit transition-transform duration-400 hover:scale-105'>{Icons.telegram}</a>
            <a href='https://www.linkedin.com/company/99209148/' target="_blank" className='underline-none text-inherit transition-transform duration-400 hover:scale-105'>{Icons.linkedin}</a>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="w-full flex flex-col text-white gap-2 md:justify-evenly lg2:text-[0.9rem] md:flex-row md:flex-wrap sm:text-[0.8rem]">
            <Link to="/#home" className="no-underline hover:underline text-black">
              Home
            </Link>
            <Link to="/#about" className="no-underline hover:underline text-black">
              About
            </Link>
            <Link
              to="/#extension"
              className="no-underline hover:underline text-black"
            >
              Chrome extension
            </Link>
            <Link to="/#contact" className="no-underline hover:underline text-black">
              Contact Us
            </Link>
           
          </div>
          <div className="md:hidden">
            <p className="font-semibold lg2:text-[0.9rem]">Get the extension</p>
            <button
              className="flex items-center rounded-3xl gap-2 bg-black text-white px-4 py-2 font-semibold"
              onClick={() =>
                toast.success("Feature in development – will be live soon!")
              }
            >
              {Icons.chrome} Install
            </button>
          </div>
        </div>
      </div>
      <div className="p-3 bg-blue-800 text-white">
        <div className="flex items-center justify-center gap-2 text-sm font-semibold">
          {Icons.copyright}
          <span className="sm:text-[0.7rem]">
            2023 DevJobs. All rights reserved
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer